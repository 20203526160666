<template>
    <div class="p-2" v-if="walker">
        <div class="d-flex align-items-center" style="gap:10px 20px">
            <div>
                <img :src="walker.user_picture ? walker.user_picture : default_img" class="rounded-circle"
                    style="height:80px;width:80px" />
            </div>
            <div class="flex-fill">
                <h3 class="text-white h3" style="margin-bottom: .25em;">{{ walker.user_name }}</h3>
                <h3 class="text-white h5 mb-0">Rating : Coming Soon</h3>
            </div>
            <router-link :to="`/chats/${walker.user_id}`" v-if="user.user_id != walker.user_id">
                <div class="text-white d-flex align-items-center"
                    style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                    <i class="bx bxs-message-dots" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                </div>
            </router-link>
            <router-link :to="`/walker-register`" v-else>
                <div class="text-white d-flex align-items-center"
                    style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                    <i class="bx bxs-edit" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                </div>
            </router-link>
        </div>

        <p class="text-white mt-1">
            {{ walker.walker_note }}
        </p>

        <table>
            <tr>
                <td>
                    <h4 class="mb-0 text-white">Availability</h4>
                </td>
                <td style="width:50px" class="text-center">
                    <h4 class="mb-0 text-white">:</h4>
                </td>
                <td>
                    <h4 class="mb-0 text-success" v-if="walker.walker.availability == 'y'">Available</h4>
                    <h4 class="mb-0 text-danger" v-else>Non Available</h4>
                </td>
            </tr>
            <tr>
                <td>
                    <h4 class="mb-0 text-white">Location</h4>
                </td>
                <td style="width:50px" class="text-center">
                    <h4 class="mb-0 text-white">:</h4>
                </td>
                <td>
                    <h4 class="mb-0 text-white">{{ walker.walker.walker_location }}</h4>
                </td>
            </tr>
            <tr>
                <td>
                    <h4 class="mb-0 text-white">Preferred Pet/Size</h4>
                </td>
                <td style="width:50px" class="text-center">
                    <h4 class="mb-0 text-white">:</h4>
                </td>
                <td>
                    <h4 class="mb-0 text-white">{{ walker.walker.preferred_pet }} / {{ walker.walker.preferred_breed }}</h4>
                </td>
            </tr> 
            <tr>
                <td>
                    <h4 class="mb-0 text-white">Note</h4>
                </td>
                <td style="width:50px" class="text-center">
                    <h4 class="mb-0 text-white">:</h4>
                </td>
                <td>
                    <h4 class="mb-0 text-white">{{ walker.walker.walker_note }}</h4>
                </td>
            </tr>
        </table>

        <div class="d-flex mt-1">
            <div class="badge bg-danger" v-if="walker.walker.is_approved == 'n'">
                Waiting for approval
            </div>
        </div>

        <hr class="my-2" />
        
        <div v-if="user.user_id != walker.user_id">
        <!-- <div v-if="true"> -->
            <h3 class="font-weight-bold text-white">Book Schedule</h3>

            <form id="form">
                <div class="d-flex" style="gap:10px">
                    <div class="flex-fill">
                        <select class="form-control w-100" v-model="pet" placeholder="Choose Your Pet" required 
                            style="background: transparent;border:2px solid #E3A230;" >
                            <option selected :value="null">
                                Choose Your Pet
                            </option>
                            <option v-for="(item, key) in pets" :value="item.pet_id">
                                {{ item.pet_name }}
                            </option>
                        </select>
                        <div class="badge badge-danger text-white" v-if="!valid.pet && isSubmit" style="margin-top: .5em;">Pet not valid</div>
                    </div>
                    <div class="flex-fill" style="max-width: 120px;">
                        <select class="form-control w-100" v-model="service" placeholder="Choose Service" required 
                            style="background: transparent;border:2px solid #E3A230;" >
                            <option selected :value="null">
                                Choose Service
                            </option>
                            <option value="Sitting">Sitting</option>
                            <option value="Walking">Walking</option>
                        </select>
                        <div class="badge badge-danger text-white" v-if="!valid.service && isSubmit" style="margin-top: .5em;">Service not valid</div>
                    </div>
                </div>
                <div class="d-flex align-items-center my-1" style="gap:10px">
                    <div class="flex-fill">
                        <input class="w-100 form-control" v-model="ws_date" type="date" required
                            style="background: transparent;color: white;border:2px solid #E3A230;" />
                        <div class="badge badge-danger text-white" v-if="!valid.ws_date && isSubmit" style="margin-top: .5em;">Date not valid</div>
                    </div>
                    <div class="flex-fill">
                        <input class="w-100 form-control" v-model="ws_time" type="time" required
                            style="background: transparent;color: white;border:2px solid #E3A230;" />
                        <div class="badge badge-danger text-white" v-if="!valid.ws_time && isSubmit" style="margin-top: .5em;">Time not valid</div>
                    </div>
                </div>
                
                <textarea rows="3" class="form-control" placeholder="Note For Walker" v-model="ws_note" required
                    style="background: transparent;color: white;border:2px solid #E3A230;">
                </textarea>
                <div class="badge badge-danger text-white" v-if="!valid.ws_note && isSubmit" style="margin-top: .5em;">Note not valid</div>
            </form>

            <div class="mt-2 w-100 d-flex flex-column justify-content-center" style="gap:10px">
                <button class="btn text-white flex-fill" @click="onSubmit()" style="background-color: #187294;height:50px;font-size: 18px;">
                    Book Now
                </button> 
            </div>

        </div>

        <h3 class="font-weight-bold text-white mt-2" v-if="user.user_id != walker.user_id">Booked In</h3>
        <h3 class="font-weight-bold text-white mt-2" v-else>Request In</h3>

        <div v-for="item in schedule_list">
            <div class="d-flex" style="gap:10px 20px;margin-top:2em">
                <div>
                    <img :src="item.pet.pet_picture ? item.pet.pet_picture : default_img" class="rounded-circle"
                        style="height:80px;width:80px" />
                </div>
                <div class="flex-fill">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h3 class="text-white h3" style="margin-bottom: .25em;">{{ item.pet.pet_name }}
                            </h3>
                            <div class="badge bg-secondary" v-if="!item.is_approved">
                                Waiting for approval
                            </div>
                            <div class="badge bg-danger" v-else-if="item.is_approved == 'n'">
                                Rejected
                            </div>
                            <div class="badge bg-success" v-else-if="item.is_approved == 'y'">
                                Accepted
                            </div>
                        </div>
                        <router-link :to="`/bussiness/walker-book-detail/${item.ws_id}`">
                            <div class="text-white d-flex align-items-center"
                                style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                                <i class="bx bx-chevron-right" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                            </div>
                        </router-link>
                    </div>
                    <div class="d-flex align-items-center text-white" style="gap:10px;margin-top:.5em">
                        <div>Booked at</div>
                        <div>{{ item.ws_date }}</div>
                        <div>{{ item.ws_time }}</div>
                    </div>
                    <table class="">
                        <tr>
                            <td><h3 class="text-white h5 mb-0">Breed</h3></td>
                            <td><h3 class="text-white h5 mb-0 text-center" style="width: 50px;">:</h3></td>
                            <td><h3 class="text-white h5 mb-0">{{item.pet.pet_breed}}</h3></td>
                        </tr>
                        <tr>
                            <td><h3 class="text-white h5 mb-0">Location</h3></td>
                            <td><h3 class="text-white h5 mb-0 text-center" style="width: 50px;">:</h3></td>
                            <td><h3 class="text-white h5 mb-0">{{item.user.user_location}}</h3></td>
                        </tr>
                        <tr>
                            <td><h3 class="text-white h5 mb-0">Service</h3></td>
                            <td><h3 class="text-white h5 mb-0 text-center" style="width: 50px;">:</h3></td>
                            <td><h3 class="text-white h5 mb-0">{{item.service}}</h3></td>
                        </tr>
                    </table> 
                </div>  
            </div>
            <div class="d-flex align-items-center mt-1" style="gap:10px" v-if="user.user_id == walker.user_id && !item.is_approved">
                <div class="text-white btn w-100" @click="change_status(item.ws_id, 'y')"
                    style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                    <div class="h4 text-white mb-0">Accept</div>
                </div>
                <div class="text-white btn w-100" @click="change_status(item.ws_id, 'n')"
                    style="padding:.5em 3em;gap:10px;background-color: #C10000;border: 2px solid #FFAD32;border-radius: 20px;">
                    <div class="h4 text-white mb-0">Reject</div>
                </div>
            </div>
        </div> 

    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie'

let cookies = new Cookies()

export default {
    computed: {
        user() {
            return store.state.auth.USER
        },
        pets(){
            return store.state.pet.PET
        },
        walker() {
            return store.state.walker.WALKER_DETAIL
        },
        schedule_list() {
            return store.state.walker.SCHEDULE_LIST
        },
        user_walker(){
            return {
                user : this.user,
                walker: this.walker
            }
        },
        valid(){
            return {
                ws_date : this.ws_date?.length > 0, 
                ws_time : this.ws_time?.length > 0, 
                ws_note : this.ws_note?.length > 0, 
                pet : this.pet?.toString()?.length > 0, 
                service : this.service?.length > 0,  
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    },
    watch: {
        user_walker(val){
            if(val){
                this.getScheduleList()
            }
        }
    },
    methods: {
        change_status(id, status){
            let payload = new FormData()
            payload.append('id', id)
            payload.append('inp[is_approved]', status)

            store.dispatch('walker/SaveSchedule', payload).then((res) => { 
                this.getScheduleList()
            }) 
        },
        onSubmit(){
            this.isSubmit = true
            if(this.ws_date && this.ws_time && this.ws_note && this.pet && this.service){
                let payload = new FormData()
                payload.append('inp[ws_date]', this.ws_date)
                payload.append('inp[ws_time]', this.ws_time) 
                payload.append('inp[ws_note]', this.ws_note) 
                payload.append('inp[walker_id]', this.id) 
                payload.append('inp[service]', this.service) 
                payload.append('inp[pet_id]', this.pet) 
                payload.append('inp[user_id]', cookies.get('token'))
    
                store.dispatch('walker/SaveSchedule', payload).then((res) => { 
                    // this.getScheduleList()
                    this.$router.push('/bussiness/walker-book-detail/'+res.id)
                }) 

                this.ws_date = null
                this.ws_time = null
                this.ws_note = null
                this.pet = null
                this.service = null
                this.isSubmit = false
            }
        },
        getScheduleList(){
            let payload = {
                isOwner: this.id,
            }
            if(this.user?.user_id != this.walker?.user_id)
            payload = {
                ...payload,
                isNotOwner: this.user.user_id
            }
            store.dispatch('walker/GetScheduleList', payload)
        }
    },
    mounted() {
        store.dispatch('walker/GetWalker', this.id)
        store.dispatch('pet/GetPetList', {
            user_id: cookies.get('token')
        }) 
    },
    data() {
        return {
            ws_date: null,
            ws_time: null,
            ws_note: null,
            pet: null,
            service: null,
            isSubmit: false,

            id: this.$route.params.id,
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>

<style>
    select.form-control, select.form-control:active {
        color:white !important
    }
    select.form-control option, select.form-control:active option {
        color:white !important;
        background-color: #1B628C !important;
    }
</style>